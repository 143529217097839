<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-file-signature"></i> ข้อมูลสัญญาซัพพลายเออร์</h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body">
          <page-supplier-contact/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pageSupplierContact from "./view/page.supplier_contact.vue";
export default {
    name: 'supplier_cntact-index',
    methods: {},
    components: {
      pageSupplierContact
    }
}
</script>
